import React from 'react'
import { FibaCheckbox, FibaInput, FibaInputNumber, FibaPhotoInput, Form } from '../components'
import { Field } from '@progress/kendo-react-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const ModuleDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className='mx-auto max-w-7xl'>
      <Form
        initialValues={{ is_public: 0 }}
        detail={'ml000005'}
        create={'ml000002'}
        update={'ml000003'}
        id={id}
      >
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
          <Field
            name={'logo'}
            component={FibaPhotoInput}
            label={t('logo')}
            required={true}
          />
          <Field
            name={'name'}
            component={FibaInput}
            label={t('name')}
            required={true}
          />
          <Field
            name={'name2'}
            component={FibaInput}
            label={t('name2')}
            required={true}
          />
          <Field
            name={'description'}
            component={FibaInput}
            label={t('description')}
            required={true}
          />
          <Field
            name={'is_public'}
            component={FibaCheckbox}
            label={t('is_public')}
            required={true}
          />
          <Field
            name={'listorder'}
            component={FibaInputNumber}
            label={t('listorder')}
            required={false}
          />
        </div>
      </Form>
    </div>
  )
}

export default ModuleDetail